.header-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: var(--bg-primary-header);
  height: var(--height-header);
  .title {
    display: flex;
    align-items: stretch;

    .main-title {
      display: flex;
      align-items: center;
      background-color: #ffc800;

      h2 {
        color: black !important;
        font-weight: 800;
        padding: 0 var(--padding-medium);
        transition: 0.3s;
        &:hover {
          color: #5c18c9 !important;
          // text-shadow: 0 0 5px rgb(38, 3, 71);
        }
        @media screen and (min-width: 450px) and (max-width: 590px) {
          font-size: 14px;
        }
        @media screen and (min-width: 0px) and (max-width: 450px) {
          font-size: 12px;
        }
      }
    }

    h3 {
      margin-top: auto;
      padding: 0 var(--padding-medium);
      color: var(--text-primary);
      font-weight: 800;
      display: flex;
      align-items: center;
      @media screen and (min-width: 0px) and (max-width: 590px) {
        font-size: 14px;
      }
      @media screen and (min-width: 0px) and (max-width: 450px) {
        font-size: 12px;
      }
    }
  }
}

.header-links {
  .menu_bar {
    background: transparent;
    padding: 1rem 1.5rem;
    border: none;
    font-weight: 700;
    font-size: 1.2rem;
    transition: 0.3s ease-in;
    color: #fff;
    border-left: 1px solid #4a4a4a;
    .fa {
      margin-left: 12px;
    }
  }
  @media screen and (min-width: 0px) and (max-width: 380px) {
    .display_none {
      display: none;
    }
  }
  .p-sidebar-left {
    width: 50% !important;
    @media screen and (min-width: 0px) and (max-width: 480px) {
      width: 100% !important;
    }
  }
  .p-sidebar {
    background: #292929 !important;
    .p-sidebar-content {
      display: flex !important;
      flex-direction: column !important;
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: 1150px) and (max-width: 2500px) {
      display: none;
    }
  }

  display: flex;
  align-items: stretch;
  font-size: 1.2rem;
  .menu_links {
    padding: 1rem;
    font-weight: 600;
    transition: 0.3s;
    color: var(--text-primary);

    &:hover {
      text-decoration: none;
      color: white;
      background: var(--btn-hover-primary);
      cursor: pointer;
    }
  }

  a {
    padding: 1rem;
    font-weight: 600;
    transition: 0.3s;
    color: var(--text-primary);
    @media screen and (min-width: 0px) and (max-width: 1150px) {
      margin-bottom: 10px;
    }
    i {
      font-size: inherit;
    }
    &:hover {
      text-decoration: none;
      color: white;
      background: var(--btn-hover-primary);
      cursor: pointer;
    }
  }
  .selectedUrl {
    background: var(--btn-active);
  }
}

.profile-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 2rem;
  border-bottom: var(--border-primary-thin);

  label {
    margin: 0;
    padding: 0.25rem 1rem;
    color: rgb(175, 175, 175);

    &:last-child {
      font-size: 0.85rem;
    }

    &:first-child {
      color: rgb(223, 223, 223);

      font-size: 1.2rem;
    }
  }
}

div.drop-btn {
  position: relative;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  padding: 0 var(--padding-medium);
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  @media screen and (min-width: 0px) and (max-width: 1150px) {
    margin-bottom: 10px;
    padding: 1rem;
  }
  .avatar {
    // background: var(--bg-contrast-light);
    color: var(--text-primary);
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    align-items: center;
    height: 2.75rem;
    border: var(--border-light-thin);
    width: 2.75rem;
    border-radius: 50%;
    margin-right: var(--margin-medium);
  }

  &:hover {
    background: var(--btn-hover-primary);
    cursor: pointer;

    .drop-menu {
      display: flex;
      @media screen and (min-width: 0px) and (max-width: 1150px) {
        width: 100%;
        margin-top: 5px;
      }
    }
  }

  .drop-menu {
    position: absolute;
    top: 102%;
    right: 0;
    z-index: 700;
    display: none;
    flex-direction: column;
    background: var(--bg-primary-header);
    min-width: 15rem;
    box-shadow: 0 4px 5px #17111171;

    span {
      display: block;
      padding: var(--padding-medium);

      &:hover {
        background: var(--btn-hover-primary);
      }
    }
  }
}
