.add-scr-profile-form {
  display: flex;
  flex-direction: column;
  background: var(--card-primary);
  border-radius: 0.5rem;
  padding: var(--padding-medium);
  width: 40rem;
  height: calc(100vh - 16.5vh);
}
.search-ip {
  background: var(--bg-input-secondary) !important;
}

.profile-card-container {
  background: #454544b4;
  border-radius: var(--radius-50);
  padding: var(--padding-medium);
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  .profile-card {
    background: var(--card-primary);
    border-radius: var(--radius-50);
    padding: 0 var(--padding-big);
    .label {
      font-size: 0.85rem;
      font-weight: 700;
      font-style: italic;
      color: rgb(126, 126, 126);
    }

    header,
    section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: var(--border-primary-thin);
      .deduction {
        .perc {
          font-size: 2.5rem;
          color: rgb(202, 202, 202);
          font-weight: 700;
          text-align: center;
        }
      }
    }
    section {
      padding: 2rem 0 2rem 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border: none;

      .deduction .perc {
        font-size: 1.5rem;
      }
    }

    footer {
      display: flex;
      justify-content: flex-end;
      padding: var(--padding-medium) var(--padding-regular);
      border-top: var(--border-primary-thin);

      button.del {
        background: var(--color-red);
        color: var(--text-primary);
        border-radius: var(--radius-50);
        border: none;
        padding: 0.5rem 0.75rem;
        box-shadow: 0 0 6px rgba(41, 39, 46, 0.685);
        transition: 0.3s;

        &:hover {
          background: rgb(240, 69, 69);
          color: white;
          box-shadow: 0 0 6px 8px rgba(41, 39, 46, 0.685);
        }
      }
    }
  }
}

div.profile-name {
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0.5rem;
}

.profile-container {
  padding: var(--padding-medium);
  button.profile {
    width: 100%;
    padding: 1rem;
    border-radius: var(--radius-35);
    box-shadow: 0 0 14px 4px #36363698;
    border: none;
    margin-bottom: var(--margin-medium);
    background: #303030;
    color: var(--text-primary);
    transition: 0.3s;
    display: flex;
    justify-content: space-between;

    &:hover {
      background: var(--btn-hover-primary);
    }

    &.active {
      background: var(--btn-active-dark);
    }
  }
}

@media screen and (max-width: 1140px) {
  .profile-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 756px) {
  .profile-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
