.backDrop {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  z-index: 10;
  background-color: rgba(240, 240, 240, 0.082);
}
.noBackDrop {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 10;
}
