.layout-1 {
  // Heights
  --height-header: 45px;
  --height-footer: 60px;
  --height-menu: 50px;
  --content-height: calc(100vh - (var(--height-header) + var(--height-footer)));
  //Border Radius
  --radius-35: 0.35rem;
  --radius-50: 0.5rem;
  // Margin Padding
  --padding-regular: 0.5rem;
  --padding-medium: 1rem;
  --padding-big: 1.5rem;
  --margin-regular: 0.5rem;
  --margin-medium: 1rem;
  --margin-big: 1.5rem;
}

.dark {
  --color-ashva-title: #fac800;
  // Colors
  --color-primary: #883cae;
  --color-primary-light: #c47dff;
  --color-light: #f8f8f8;
  --color-dark: #202020;

  --color-blue: #4d82f5;
  --color-green: #4df577;
  --color-red: #a94c4c;
  --color-orange: #ffa53d;

  //DataTable
  --table-body: #000000a6;
  //dialog
  --dialog-header-footer: #616161;
  --dialog-content: #262626;
  // Backgrounds
  --bg-primary: #525252;
  --bg-contrast-light: #ffffff;
  // Header Backgrounds
  --bg-primary-header: #323232;
  --bg-secondary-header: #353535a6;
  --bg-ternary-header: #8d8d8d;

  // Sidebar
  --sidebar-dark: #464646;
  --sidebar-transparent: #00000000;
  // Text
  --text-primary: #ffffff;
  --text-secondary: #cccccc;
  --text-danger: #ff4444;
  --text-contrast: #323232;
  --text-note: #b9b9b9fd;
  --text-Non-Active:#6d6c6c;

  // Button Backgrounds
  --btn-primary: var(--color-primary);
  --btn-dark: #2e2e2e;

  --btn-hover-primary: #ae55f72f;
  --btn-active: #9c2bf841;
  --btn-active-dark: var(--color-primary);
  --btn-hover-primary-dark: #ba5aeb;

  // Border
  --border-primary-thin: thin solid #83838345;
  --border-primary-thick: thick solid #83838345;
  --border-seconday-thick: thick solid #5c5c5cd8;
  --border-light-thick: thick solid #ffffff4d;
  --border-light-thin: thin solid #ffffff4d;
  --border-light-medium: 2px solid #ffffff4d;
  --border-focus-thin: thin solid #762fb1be;

  // Inputs
  --bg-input-primary: #464646;
  --bg-input-secondary: #75757569;

  // Labels
  --label-muted-color: #bbbbbb;

  // Status
  --status-open: #4d82f5;
  --status-closed: #fa6c53;
  --status-resulted: #63b35c;
  --disabled-status: #8a8a8a;

  // Table COlors
  --table-header-dark: #353535;
  --table-body-dark: #4d4b4b;
  // Card colors
  --card-primary: #353535a6;
  --card-header-primary: #343434;

  //   Cards
}

.dark-blue {
  --color-ashva-title: #fac800;
  // Colors
  --color-primary: #42d664;
  --color-primary-light: #b8f5c6;
  --color-light: #f8f8f8;

  --color-blue: #4d82f5;
  --color-green: #4df577;
  --color-red: #a94c4c;
  --color-orange: #ffa53d;
  // Backgrounds
  --bg-primary: #2c3746;
  --bg-contrast-light: #ffffff;
  // Header Backgrounds
  --bg-primary-header: #1a212a;
  --bg-secondary-header: #1a212ad0;
  --bg-ternary-header: #303c4d;

  // Sidebar
  --sidebar-dark: #21252b;
  --sidebar-transparent: #00000000;
  // Text
  --text-primary: #ffffff;
  --text-secondary: #cccccc;
  --text-danger: #ff4444;
  --text-contrast: #323232;
  --text-note: #b9b9b9fd;

  // Button Backgrounds
  --btn-primary: var(--color-primary);
  --btn-dark: #2e2e2e;
  --btn-hover-primary: #23863a;
  --btn-active: #348d49cc;
  --btn-active-dark: #337c44;
  --btn-hover-primary-dark: #38924f;

  // Border
  --border-primary-thin: thin solid #83838345;
  --border-primary-thick: thick solid #83838345;
  --border-seconday-thick: thick solid #5c5c5cd8;
  --border-light-thick: thick solid #ffffff4d;
  --border-light-thin: thin solid #ffffff4d;
  --border-light-medium: 2px solid #ffffff4d;
  --border-focus-thin: thin solid #762fb1be;

  //Border Radius
  --radius-35: 0.35rem;
  --radius-50: 0.5rem;
  // Margin Padding
  --padding-regular: 0.5rem;
  --padding-medium: 1rem;
  --padding-big: 1.5rem;
  --margin-regular: 0.5rem;
  --margin-medium: 1rem;
  --margin-big: 1.5rem;

  // Inputs
  --bg-input-primary: #272e38;
  --bg-input-secondary: #272e38c0;

  // Labels
  --label-muted-color: #bbbbbb;

  // Status
  --status-open: #4d82f5;
  --status-closed: #fa6c53;
  --status-resulted: #63b35c;
  --disabled-status: #8a8a8a;

  // Table COlors
  --table-header-dark: #131a25e5;

  // Card colors
  --card-primary: #232a33;
  --card-header-primary: #131a25e5;

  // Heights
  --height-header: 45px;
  --height-footer: 40px;
  --height-menu: 50px;
}

.light-blue {
  --color-ashva-title: #fac800;
  // Colors
  --color-primary: #1982e4;
  --color-primary-light: #b8f5c6;
  --color-light: #f8f8f8;

  --color-blue: #4d82f5;
  --color-green: #4df577;
  --color-red: #a94c4c;
  --color-orange: #ffa53d;
  // Backgrounds
  --bg-primary: #ffffff;
  --bg-contrast-light: #868686;
  // Header Backgrounds
  --bg-primary-header: #e7e7e7;
  --bg-secondary-header: #ebf4ffd0;
  --bg-ternary-header: #303c4d;

  // Sidebar
  --sidebar-dark: #dbdbdb;
  --sidebar-transparent: #00000000;
  // Text
  --text-primary: #333333;
  --text-secondary: #353535;
  --text-danger: #ff4444;
  --text-contrast: #323232;
  --text-note: #b9b9b9fd;


  // Button Backgrounds
  --btn-primary: var(--color-primary);
  --btn-dark: #f1f1f1;
  --btn-hover-primary: #6471e6;
  --btn-active: #3459ffcc;
  --btn-active-dark: #0077ff;
  --btn-hover-primary-dark: #2e4661;

  // Border
  --border-primary-thin: thin solid #83838345;
  --border-primary-thick: thick solid #83838345;
  --border-seconday-thick: thick solid #5c5c5cd8;
  --border-light-thick: thick solid #ffffff4d;
  --border-light-thin: thin solid #ffffff4d;
  --border-light-medium: 2px solid #ffffff4d;
  --border-focus-thin: thin solid #8385f7be;

  //Border Radius
  --radius-35: 0.35rem;
  --radius-50: 0.5rem;
  // Margin Padding
  --padding-regular: 0.5rem;
  --padding-medium: 1rem;
  --padding-big: 1.5rem;
  --margin-regular: 0.5rem;
  --margin-medium: 1rem;
  --margin-big: 1.5rem;

  // Inputs
  --bg-input-primary: #ffffff;
  --bg-input-secondary: #f3f3f3c0;

  // Labels
  --label-muted-color: #bbbbbb;

  // Status
  --status-open: #88adfd;
  --status-closed: #f79c8c;
  --status-resulted: #a3fc9b;
  --status-nondisplay:#646363;
  --disabled-status: #8a8a8a;

  // Table COlors
  --table-header-dark: #d6e6ffe5;

  // Card colors
  --card-primary: #f5f5f5;
  --card-header-primary: #c9c9c9e5;

  // Heights
  --height-header: 45px;
  --height-footer: 40px;
  --height-menu: 50px;
}
