$input-primary: #282931;

.pool-rules-card {
  width: 100%;
  background: var(--card-primary);
  border-bottom: var(--border-primary-thin);
  border-radius: 0.5rem;
  padding: 1.5rem;
  overflow-y: auto;
  //   table {
  //     width: 100%;
  //   }
  .rule-container {
    border-bottom: var(--border-primary-thin);
    display: grid;
    grid-template-columns: auto 4fr 1fr 2fr auto;
  }
  .empty-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 25px;
    color: var(--text-Non-Active);
  }
}
.overlay-body {
  color: white;
}
.pool-item {
  padding: 0.5rem;
  font-weight: 600;
  //   color: white;

  &:not(:last-child) {
    border-bottom: thin solid rgba(128, 128, 128, 0.356);
  }
}
.pool-btn {
  background: rgb(58, 58, 58);
  box-shadow: 2px 4px 6px 0px #252525a1;
  border-radius: 0.5rem;
  border: none;
  display: inline-block;
  padding: 0.25rem 0.75rem;
  box-shadow: 2px 4px 9px #2c2c2c85;
  transition: 0.3s;
  margin: 0 0.25rem;

  &:not(:disabled):hover {
    background: var(--btn-hover-primary);
  }

  &:disabled {
    color: rgb(112, 112, 112);
  }

  &.danger {
    background: var(--color-red) !important;
    &:hover {
      background: rgba(255, 0, 0, 0.377) !important;
    }
  }
}
.panel-multiselect {
  width: 100%;
  background: $input-primary !important;
  border: 1px solid rgba(93, 93, 110, 0.37) !important;

  .p-dropdown-label {
    color: white !important;
  }
}
.multiselect-label {
  font-weight: 600;
  font-style: italic;
  color: rgb(133, 133, 133);
  margin-bottom: 0;
  padding-left: 1rem;
}
.panel-input-container {
  display: block;
  .input-box {
    position: relative;
    padding: 1rem 0;
    input {
      width: 100%;
      border-radius: 0.5rem;
      background: $input-primary;
      padding: 0.5rem;
      //   margin: 0.5rem 0;
      outline: none;
      transition: 0.3s;
      border: 1px solid rgba(93, 93, 110, 0.37);

      &:hover,
      &:focus {
        border: thin solid rgba(167, 53, 167, 0.486);
      }
    }
    label {
      position: absolute;
      left: 10px;
      font-weight: 600;
      font-style: italic;
      color: rgb(133, 133, 133);

      top: -5px;
    }
  }
}

.update-btn {
  background: #883cae;
}
