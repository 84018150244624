.view_customer_summary_wrapper {
  width: 100%;
  height: 100%;
  .data_table_wrapper {
    .p-datatable-wrapper {
      overflow: auto;
    }
    .p-datatable table {
      table-layout: auto !important;
    }
  }
  .rule_btn {
    @media screen and (max-width: 800px) {
      padding: 0.75rem 0.5rem !important;
    }
  }
}
.info-data-container {
  padding: 10px;
  background: #525252;
  margin: 2px;
  .label {
    text-transform: uppercase;
    font-weight: bolder;
  }
}
.calender-css {
  width: 15%;
  padding: 10px;
  .p-inputtext {
    background: var(--bg-input-primary);
    border: none;
    outline: none;
    color: var(--text-primary);
  }
}
.sub_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  background: var(--bg-primary-header);
  .pz-card {
    margin: 0px;
  }
}
.month-bid-info-secondary {
  font-size: 15px;
  display: block;
  font-weight: 500;
  border-bottom: 1px solid #00000020;
  padding-bottom: 0.5rem;
  margin-bottom: 0.3rem;
}
.currencyWrp {
  font-size: 12px;
  padding-left: 0.5rem;
  color: #969696;
}
.card {
  padding: 0px !important;
  .card-header {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
  }
  .card_content {
    padding: 10px 20px 10px 20px;
  }
  .overAllBtn {
    cursor: pointer;
    border: 1px solid #575656;
    border-radius: 0.3rem;
    padding: 0.2rem 1rem;
    background-color: #212121;
    color: #6d6c6c;
    font-size: 14px;
  }
  .active {
    background-color: var(--btn-primary);
    color: #fff;
    border: none;
  }
}
.empty-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  font-size: 18px;
  font-family: "poppins", sans-serif;
  color: var(--text-Non-Active);
}
.month_bid_info {
  font-size: 15px;
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}
.btn-container {
  align-items: center;
  align-self: center;
}
.errMsg {
  font-size: 20px;
  background: #464646;
  display: flex;
  justify-content: center;
  padding: 15px;
  margin: 15px;
  color: var(--text-Non-Active);
  border-radius: 0.5rem;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  margin: 20px;
  padding: 20px;
  width: 500px;
  min-height: 200px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}

.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  // transform: scale(1.01);
}

/* CARD BACKGROUNDS */

.card-1 {
  // background: radial-gradient(#1fe4f5, #3fbafe);
  background: radial-gradient(#383838, #212121);
}

.p-datatable-header {
  border: none !important;
  // border-bottom: 1px solid #dee2e67d !important;
}
.p-datatable .p-datatable-tfoot > tr > td {
  background: #353535 !important;
  color: #fff !important;
}

.tab-view-session {
  .p-tabview-panels {
    background: radial-gradient(#383838, #212121) !important;
    padding: 0.25rem !important;
    color: #f8f8ff !important;
  }
  .p-tabview-nav {
    padding: 5px !important;
  }
  .p-tabview-nav li .p-tabview-nav-link {
    background: var(--bg-primary) !important;
    color: var(--text-primary) !important;
    padding: 0.75rem 1rem !important;
  }
  .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: var(--card-primary) !important;
    border-color: var(--text-primary) var(--text-primary) var(--text-primary) !important;
    color: var(--text-primary) !important;
  }
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .cards {
    justify-content: center;
  }
}
.inputWrp {
  color: #fff;
}
