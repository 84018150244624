.stepper {
  padding: 1rem;
}
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid rgb(182, 182, 182);
  width: 100%;
  top: 15px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid rgb(179, 179, 179);
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-weight: 700;
  border-radius: 50%;
  background: #ccc;
  color: rgb(44, 44, 44);
  margin-bottom: 6px;
}

.stepper-item.active {
  transition: 0.3s ease-in-out;
  font-weight: bold;
  .step-counter {
    background: #ffffff;
  }
}

.stepper-item.completed {
  transition: 0.3s;
  .step-counter {
    background-color: #ac95ff;
  }
}
.stepper-item.completed:hover,
.stepper-item.active:hover {
  cursor: pointer;
  color: orange;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ac95ff;
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.step-body-container {
  padding: 1rem;
  border-top: var(--border-primary-thin);
}
.my-label {
  margin: 0;
  color: var(--label-muted-color);
}

.input-grid {
  display: grid;
  grid-template-columns: 30% 35% 35%;
  div {
    margin: 0.75rem 0;
  }
}

.selector {
  border-radius: var(--radius-35);
  background: var(--bg-input-primary);

  border: var(--border-primary-thin);
  &.country {
    margin: 0 var(--margin-regular);
  }

  &.tracks {
    margin-top: 1rem;
  }

  .track-grid {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 0.5rem;
    max-height: 40vh;
    overflow: auto;
    button {
      background: var(--card-primary);
      margin: 0.25rem;
      padding: 0.5rem 1rem;
      border-radius: var(--radius-50);
      box-shadow: 0 0 5px #24242479, 0 0 5px #545454c7;
      border: var(--border-primary-thin);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      text-align: left;
      &:hover {
        background: var(--btn-hover-primary);
      }
      &.active {
        background: var(--btn-active);
      }
    }
  }
  .country-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    max-height: 40vh;
    overflow: auto;
    .country-card {
      background: var(--card-primary);
      margin: 0.5rem;
      border-radius: var(--radius-50);
      box-shadow: 0 0 5px #24242479, 0 0 5px #545454c7;
      height: 3rem;
      border: var(--border-primary-thin);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: large;

      &:hover {
        background: var(--btn-hover-primary);
      }

      &.active {
        background: var(--btn-active);
      }
    }
  }
  .search-container {
    padding: var(--padding-medium);
    border-bottom: var(--border-primary-thick);
    input {
      outline: none;
      transition: 0.3s;
      padding: var(--padding-medium);
      width: 100%;
      border-radius: var(--radius-35);
      border: var(--border-primary-thin);
      background: rgba(104, 101, 101, 0.616);
      &::placeholder {
        color: rgba(255, 255, 255, 0.363);
      }
    }
  }
}
.track-detail {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  width: 100%;
  .track-name {
    font-size: 1.25rem;
  }
  .race-no {
    font-size: 3.5rem;
    font-weight: 700;
  }
}
.detail {
  display: grid;
  grid-template-columns: auto auto;
  .detail-container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    text-align: center;
    label {
      font-size: 0.9rem;
      color: rgb(153, 153, 153);
      font-weight: 700;
      font-style: italic;
      margin: 0;
    }
    span {
      font-weight: 600;
      font-size: 1.1rem;
    }
  }
}

.detail-card-header {
  display: flex;
  align-items: stretch;
  border-bottom: var(--border-primary-thin);
  font-weight: 600;
  .event-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-right: var(--border-primary-thin);
  }

  .track-name {
    font-size: 1.3rem;
  }
}

.boxes-container {
  padding: var(--padding-regular);
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  //   transition: 0.5s ease-in-out;
  height: 175px;
}

.hide-boxes {
  opacity: 0;
  animation-name: _height;
  height: 0;
  padding: 0;
}

@keyframes _height {
  from {
    height: 175px;
  }
  to {
    height: 0;
  }
}
.toggle-btn {
  background: transparent;
  border: none;
  padding: 10px;
  border-radius: 0.5rem;
  border-right: var(--border-primary-thin);
  padding-right: 2rem;

  transition: 0.3s;
  &:hover {
    background: var(--btn-hover-primary);
    // color: rgb(255, 176, 255);
  }
}

.num-input-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
._grid {
  display: grid;
}
